import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import SearchAccessory from "../components/Accessory";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
import { useState, useEffect } from "react";

const Container = styled.div``;

const Hero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "none",
    })}
`;

const MobileHero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: none;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "grid",
    })}
`;

const Accessory = () => {
    const cat = "all";
    // eslint-disable-next-line
    const [filters, setFilters] = useState({});
    // eslint-disable-next-line
    const [sort, setSort] = useState("newest");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Announcement />
            <Navbar />

            <Hero
                style={{
                    backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_Desktop10_lrhIxOKHt.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669370067100`}`,
                }}
            ></Hero>
            <MobileHero
                style={
                    {
                        backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_Mobile10_JldvKQ-Lj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669619983686`}`,
                    }
                }
            ></MobileHero>
            <SearchAccessory cat={cat} filters={filters} sort={sort} />
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default Accessory;
