import { SearchOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { useState, useEffect } from "react";

const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
`;

const Container = styled.div`
    flex: 1;
    padding: 25px 0rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: relative;
    margin: 1rem;
    font-size: 1.2rem;

    &:hover ${Info} {
        opacity: 1;
    }
    ${mobile({ minWidth: "0", width: "100%", margin: "0", fontSize: "1rem" })}
    ${tablet({ minWidth: "0", width: "100%", margin: "0" })}
`;

const Label = styled.div`
font-family: "FuturaPT-Bold";
background: black;
font-weight: 600;
color: red;
text-align: center;
max-width: 25ch;
padding: 0.5rem 1rem;
${mobile({
    padding: "0rem 1rem 0.5rem 1rem",
    fontSize: "1rem",
})}
${tablet({
    padding: "0rem 1rem 0.5rem 1rem",
    fontSize: "1.2rem",
})}
`;

const Title = styled.div`
    font-family: "FuturaPT-Bold";
    font-weight: 600;
    color: white;
    text-align: center;
    max-width: 25ch;
    padding-top: 0.5rem;
    ${mobile({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1rem",
    })}
    ${tablet({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1.2rem",
    })}
`;

const Image = styled.img`
    height: 30vh;
    padding-bottom: 1rem;
    z-index: 2;
    ${mobile({
        height: "22vh",
    })}
    ${tablet({
        height: "35vh",
    })}
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
        background-color: #e9f5f5;
        transform: scale(1.1);
    }
`;

const AlternateProduct = ({ item }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    let imageList = [];

    const watchTitle = `HAOFA ${item.model}`;

    for (const images of item.color) {
        imageList.push(images.img);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === imageList.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, Math.floor(Math.random() * (8000 - 6000 + 1) + 1000));

        return () => clearInterval(intervalId);
    }, [currentIndex, imageList.length]);

    return (
        <Container
            style={
                window.location.pathname === "/" && window.innerWidth < 480
                    ? { display: "none" }
                    : { display: "flex" }
            }
        >
            <Image src={imageList[currentIndex]} />
            <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/product/${item.model}`}
            >
                <Info>
                </Info>
            </Link>
            <Label>
                BEST SELLER
            </Label>
            <Title>
                {watchTitle.length > 20
                    ? watchTitle.substring(0, 20) + "..."
                    : watchTitle}
            </Title>

        </Container>
    );
};

export default AlternateProduct;
