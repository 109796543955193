import { Add, Remove } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile, tablet } from "../responsive";
import StripeCheckout from "react-stripe-checkout";
import { useEffect, useState } from "react";
import { userRequest } from "../requestMethods";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {
    addQuantity,
    applyDiscount,
    clearDiscount,
    applyAffiliateCode,
} from "../redux/cartRedux";

const KEY = process.env.REACT_APP_STRIPE;

const Container = styled.div``;

const Wrapper = styled.div`
    padding: 20px;
    ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
    font-weight: 300;
    text-align: center;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

const TopButton = styled.button`
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    border: ${(props) => props.type === "filled" && "none"};
    background-color: ${(props) =>
        props.type === "filled" ? "black" : "transparent"};
    color: ${(props) => props.type === "filled" && "white"};
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const Info = styled.div`
    flex: 3;
`;

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    ${mobile({ flexDirection: "column", alignItems: "center" })}
    ${tablet({ flexDirection: "column", alignItems: "center" })}
`;

const Image = styled.img`
    width: 200px;
`;

const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    ${mobile({ padding: "2rem 1rem" })}
    ${tablet({ padding: "4rem 1rem" })}
`;

const ProductSize = styled.div`
    ${mobile({ padding: "0.25rem" })}
    ${tablet({ padding: "0.5rem" })}
`;

const PriceDetail = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${mobile({ flexDirection: "row", justifyContent: "space-around" })}
    ${tablet({ flexDirection: "row", justifyContent: "space-around" })}
`;

const ProductAmountContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const ProductAmount = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 24px;
    ${mobile({ height: "30px", width: "30px", margin: "5px 15px" })}
    ${tablet({ height: "30px", width: "30px", margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
    font-size: 30px;
    font-weight: 200;
    ${mobile({ marginBottom: "20px", fontSize: "22px" })}
    ${tablet({ marginBottom: "20px", fontSize: "22px" })}
`;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;

const Summary = styled.div`
    flex: 1;
    border: 0.5px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
`;

const SummaryTitle = styled.h1`
    font-weight: 200;
    ${mobile({ fontSize: "1.5rem" })}
    ${tablet({ fontSize: "2rem" })}
`;

const SummaryItem = styled.div`
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: ${(props) => props.type === "total" && "500"};
    font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
`;

const SerialCode = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    margin: 30px 0px;
    font-weight: ${(props) => props.type === "total" && "500"};
    font-size: ${(props) => props.type === "total" && "24px"};
`;

const InputContainer = styled.div`
    width: 100%;
    height: 37px;
    background-color: white;
    display: flex;
    border: 1px solid lightgray;
    margin-right: 10px;
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.8rem" })}
`;

const Cart = () => {
    const cart = useSelector((state) => state.cart);
    const [stripeToken, setStripeToken] = useState(null);
    const [checkAffiliateCode, setCheckAffiliateCode] = useState(null);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [affiliateCode, setAffiliateCode] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user);

    let totalPrice = 0

    cart.products.forEach(item => {
        totalPrice += item.price * item.quantity;
    });

    // cart.products.forEach(item => {
    //     let productDiscount;
    
    //     switch (item.model) {
    //         case "2323":
    //         case "1909L":
    //         case "1909L-1":
    //         case "2203":
    //         case "1901-1":
    //             productDiscount = 580;
    //             break;
    //         case "2290":
    //         case "2291":
    //             productDiscount = 880;
    //             break;
    //         case "2000":
    //             productDiscount = 900;
    //             break;
    //         case "1986":
    //             productDiscount = 380;
    //             break;
    //         case "1978":
    //             productDiscount = 480;
    //             break;
    //         default:
    //             productDiscount = 5; // Set default value if no match
    //             break;
    //     }
    
    //     totalDiscount += (productDiscount === 5 ? item.price * 0.05 : (productDiscount * item.quantity));
    // });

    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const onToken = (token) => {
        if (totalPrice * 100 <= 0) {
            alert("Total Cannot be less than RM0.00");
        } else {
            setStripeToken(token);
        }
    };
    

    const paypalScriptOptions = {
        "client-id":
          "AfrtVQO_NUZ7SctFgv0tnuwbfTkaMTU1Aw5TOKs03huzqo2YvIq37o4FdtoKT2CpA5h0CSqSpMhcMeQj",
        currency: "MYR"
      };

    function PayPalCustomButton() {
        /**
         * usePayPalScriptReducer use within PayPalScriptProvider
         * isPending: not finished loading(default state)
         * isResolved: successfully loaded
         * isRejected: failed to load
         */
        const [{ isPending }] = usePayPalScriptReducer();
        const paypalbuttonTransactionProps = {
            style: {
                layout: 'horizontal',
                tagline: 'false'
           },
          createOrder(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice - totalDiscount
                  }
                }
              ]
            });
          },
          onApprove(data, actions) {
            /**
             * data: {
             *   orderID: string;
             *   payerID: string;
             *   paymentID: string | null;
             *   billingToken: string | null;
             *   facilitatorAccesstoken: string;
             * }
             */
            return actions.order.capture({}).then((details) => {
              alert(
                "Transaction completed by" +
                  (details?.payer.name.given_name ?? "No details")
              );

              history.push("/success", {
                address: details.purchase_units[0].shipping.address,
                gateway: "paypal",
                products: cart,
                serialCode: affiliateCode ? affiliateCode : undefined,
            });
      
              alert("Data details: " + JSON.stringify(details.purchase_units[0].shipping.address, null, 2));
            });
          }
        };
        return (
          <>
            {isPending ? <h2>Load Smart Payment Button...</h2> : null}
            <PayPalButtons {...paypalbuttonTransactionProps} />
          </>
        );
      }

    const handleCheckSerialCode = async () => {
        const res = await userRequest.post("/affiliates/propose", {
            serialCode: checkAffiliateCode,
            userId: currentUser.currentUser._id
        });
        if (res.status === 201) {
            alert("Serial Code does not exist");
        } else if (res.status === 200) {
            const discountAmount = (cart.total / 100) * res.data.discount;

            setTotalDiscount(discountAmount)

            dispatch(applyDiscount(discountAmount));
            dispatch(applyAffiliateCode(res.data.code));
            setAffiliateCode(res.data.code);
        }
    };

    const handleQuantity = (product, quantity, amount, activeColor) => {
        dispatch(addQuantity({ ...product, quantity, amount, activeColor }));
    };

    const checkUserStatus = () => {
        alert("Please register an account or login first before checking out");
        window.location.href = "/login";
    };

    useEffect(() => {
        currentUser.currentUser === null
            ? checkUserStatus()
            : console.log("logged in");

        dispatch(clearDiscount());

        window.scrollTo(0, 0);
    }, [currentUser.currentUser, dispatch]);

    useEffect(() => {
        const makeRequest = async () => {
            try {
                const res = await userRequest.post("/checkout/payment", {
                    tokenId: stripeToken.id,
                    amount: totalPrice * 100,
                    discount: totalDiscount,
                });

                history.push("/success", {
                    address: res.data.billing_details.address,
                    gateway: "stripe",
                    products: cart,
                    serialCode: affiliateCode ? affiliateCode : undefined,
                });
            } catch {}
        };
        stripeToken && makeRequest();
    }, [stripeToken, cart, totalPrice, history, affiliateCode]);

    return (
        <Container>
            <Navbar />
            <Announcement />
            <Wrapper>
                <Title>YOUR BAG</Title>
                <Top>
                    <TopButton>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                        >
                            CONTINUE SHOPPING
                        </Link>
                    </TopButton>
                </Top>
                <Bottom>
                    <Info>
                        {cart.products ? (
                            cart.products.map((product) => (
                                <Product key={product}>
                                    <ProductDetail>
                                        <Image
                                            src={
                                                product
                                                    ? product.color
                                                        ? product.color.color
                                                            ? product.color
                                                                  .color.img
                                                            : ""
                                                        : ""
                                                    : ""
                                            }
                                        />
                                        <Details>
                                            <ProductSize>
                                                <b>Product:</b> {product.title}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Model:</b> {product.model}-
                                                {product.color.color.name}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Movement Model:</b>{" "}
                                                {product.movementModel}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Power Reserve:</b>{" "}
                                                {product.powerReserve}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Price: </b>
                                                RM
                                                {product.price - totalDiscount.toLocaleString(
                                                    "en-US"
                                                )}
                                            </ProductSize>
                                        </Details>
                                    </ProductDetail>
                                    <PriceDetail>
                                        <ProductAmountContainer>
                                            <Add
                                                onClick={() =>
                                                    handleQuantity(
                                                        product,
                                                        1,
                                                        product.price,
                                                        product.color
                                                    )
                                                }
                                            />
                                            <ProductAmount>
                                                {product.quantity}
                                            </ProductAmount>
                                            <Remove
                                                onClick={() =>
                                                    handleQuantity(
                                                        product,
                                                        -1,
                                                        product.price -
                                                            product.price -
                                                            product.price,
                                                        product.color
                                                    )
                                                }
                                            />
                                        </ProductAmountContainer>
                                        <ProductPrice>
                                            RM
                                            {(
                                                product.price * product.quantity - totalDiscount
                                            ).toLocaleString("en-US")}
                                        </ProductPrice>
                                    </PriceDetail>
                                </Product>
                            ))
                        ) : (
                            <></>
                        )}
                        <Hr />
                    </Info>
                    <Summary>
                        <SummaryTitle>ORDER SUMMARY</SummaryTitle>
                        <SummaryItem>
                            <SummaryItemText>Subtotal</SummaryItemText>
                            <SummaryItemPrice>
                                RM
                                {formatter.format(totalPrice - totalDiscount)}
                            </SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>
                                Estimated Shipping
                            </SummaryItemText>
                            <SummaryItemPrice>RM 0.00</SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>Discount</SummaryItemText>
                            <SummaryItemPrice>
                                RM -{formatter.format(totalDiscount)}
                            </SummaryItemPrice>
                        </SummaryItem>

                        <SummaryItem type="total">
                            <SummaryItemText>Total</SummaryItemText>
                            <SummaryItemPrice>
                                RM
                                {formatter.format(totalPrice - totalDiscount)}
                            </SummaryItemPrice>
                        </SummaryItem>

                        <SerialCode>
                            <SummaryItemText>
                                {" "}
                                <InputContainer>
                                    <Input
                                        onChange={(e) =>
                                            setCheckAffiliateCode(
                                                e.target.value
                                            )
                                        }
                                        placeholder={"Serial Code"}
                                    />
                                </InputContainer>
                            </SummaryItemText>
                            <SummaryItemPrice>
                                {" "}
                                <Button onClick={() => handleCheckSerialCode()}>
                                    Apply{" "}
                                </Button>
                            </SummaryItemPrice>
                        </SerialCode>
                        <StripeCheckout
                            name="HAOFA TOURBILLON (M) SDN. BHD."
                            image="/favicon.ico"
                            billingAddress
                            shippingAddress
                            description={`Your total is RM ${formatter.format(
                                totalPrice - totalDiscount
                            )}`}
                            amount={(totalPrice- totalDiscount)  * 100 }
                            token={onToken}
                            stripeKey={KEY}
                            style={{ display: "none" }}
                            currency="MYR"
                        >
                            <Button>CHECKOUT NOW</Button>
                        </StripeCheckout>
                        {/* <PayPalScriptProvider
                            options={paypalScriptOptions}
                        >
                            <PayPalCustomButton />
                        </PayPalScriptProvider> */}
                    </Summary>
                </Bottom>
            </Wrapper>
            <Footer />
        </Container>
    );
};

export default Cart;
