import styled from "styled-components";

const Container = styled.div`
`;

const Announcement = () => {
  return <Container></Container>;
};

export default Announcement;
