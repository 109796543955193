import { Facebook, Instagram } from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import tiktok from "../assets/tiktok.svg";
import xiaohongshu from "../assets/xiaohongshu.svg";

const SocialContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    font-family: FuturaPT-Bold;
    font-size: 1.5rem;
    text-align: center;
    margin: 1.5rem 0rem 1rem 0;
    min-height: 25vh;
    flex-direction: row;
    ${mobile({
        flexDirection: "column",
        padding: "1rem 2rem",
    })}

    ${tablet({
        flexDirection: "column",
        padding: "1rem 2rem",
    })}
`;

const SocialIcon = styled.a`
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    background-color: #${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    &:hover {
        transform: scale(1.3);
        -webkit-transition: transform 0.4s ease-in-out;
    }
    ${mobile({
        width: "40px",
        height: "40px"
    })}
    ${tablet({
        width: "40px",
        height: "40px"
    })}
`;
const Title = styled.h1`
    letter-spacing: 0.2rem;
    margin-right: 4rem;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "2rem",
        marginRight: "0",
        marginBottom: "2rem",
        letterSpacing: "0",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "2rem",
        marginRight: "0",
        marginBottom: "2rem",
        letterSpacing: "0",
    })}
`;

const Payment = styled.img`
    width: 50%;
`;

const SameRowStart = styled.div`
    display: flex;
    justify-content: start;
    width: 60vw;
    ${mobile({
        justifyContent: "space-around",
        width: "100%"
    })}
    ${tablet({
        justifyContent: "space-around",
        width: "100%"
    })}
`;

const SameRowEnd = styled.div`
    display: flex;
    justify-content: end;
    width: 60vw;
    ${mobile({
        justifyContent: "space-around",

    })}
    ${tablet({
        justifyContent: "space-around",
    })}
`;

const Social = () => {
    return (
        <>
            <SocialContainer>
                <SameRowEnd>
                    {" "}
                    <Title>FOLLOW US</Title>
                </SameRowEnd>
                <SameRowStart>
                    {" "}
                    <SocialIcon
                        href="https://www.facebook.com/haofamalaysia/"
                        target="blank"
                        color="3B5999"
                    >
                        <Facebook />
                    </SocialIcon>
                    <SocialIcon
                        href="https://www.instagram.com/haofa_malaysia/"
                        target="blank"
                        color="E4405F"
                    >
                        <Instagram />
                    </SocialIcon>
                    <SocialIcon
                        href="https://www.tiktok.com/@haofamalaysiaofficial?_t=8eSrhYVkQTP&_r=1"
                        target="blank"
                        color="55ACEE"
                    >
                        <Payment src={tiktok}/>
                    </SocialIcon>
                    <SocialIcon
                        href="https://www.xiaohongshu.com/user/profile/64508c5c000000001400df2d?xhsshare=CopyLink&appuid=64508c5c000000001400df2d&apptime=1690858154"
                        target="blank"
                        color="f40a0a"
                    >
                        <Payment src={xiaohongshu}/>
                    </SocialIcon>
                </SameRowStart>
            </SocialContainer>
        </>
    );
};

export default Social;
