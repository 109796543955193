import styled from "styled-components";
import { mobile } from "../responsive";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Announcement from "../components/Announcement";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: black;
    ${mobile({ margin:"0" })}
`;
const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Demi;
    font-size: 40px;
    margin: 50px 0;
    background: black;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const AboutContainer = styled.div`
    padding: 3rem 5rem;
    display: grid;
    background: white;
    ${mobile({ padding: "1rem 4rem" })}
`;

const Heading = styled.h2`
    padding: 1.5rem 3rem;
    font-size: 22px;
    font-weight: 300;
    ${mobile({ textAlign: "left", padding: "0rem" })}
`;

const Desc = styled.div`
    padding: 0 3rem;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.5;
    ${mobile({ textAlign: "left", margin: "0rem 0rem", padding: "1rem 0rem" })}
`;

const Delivery = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Announcement />
            <Navbar />
            <Container>
                <Title>Delivery Policy</Title>
                <AboutContainer>
                    <Heading>General Information</Heading>
                    <Desc>
                        All orders are subject to product availability. If an
                        item is not in stock at the time you place your order,
                        we will notify you and refund you the total amount of
                        your order, using the original method of payment.
                    </Desc>
                    <Heading>Delivery Time</Heading>
                    <Desc>
                        An estimated delivery time will be provided to you once
                        your order is placed. Delivery times are estimates and
                        commence from the date of shipping, rather than the date
                        of order. Delivery times are to be used as a guide only
                        and are subject to the acceptance and approval of your
                        order. Unless there are exceptional circumstances, we
                        make every effort to fulfill your order within [15]
                        business days of the date of your order. Business day
                        mean Monday to Friday, except holidays. Please note we
                        do not ship on [Sundays].
                    </Desc>
                    <Heading>Shipping Costs</Heading>
                    <Desc>
                        Shipping costs are based on the weight of your order and
                        the delivery method. To find out how much your order
                        will cost, simple add the items you would like to
                        purchase to your cart, and proceed to the checkout page.
                        Once at the checkout screen, shipping charges will be
                        displayed. Additional shipping charges may apply to
                        remote areas or for large or heavy items. You will be
                        advised of any charges on the checkout page. Sales tax
                        is charged according to the province or territory to
                        which the item is shipped.
                    </Desc>
                    <Heading>Damaged Items in Transport </Heading>
                    <Desc>
                        If there is any damage to the packaging on delivery,
                        contact us immediately at haofa.my/contact.
                    </Desc>
                </AboutContainer>
            </Container>
            <Newsletter />
            <Footer />
        </>
    );
};

export default Delivery;
