import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest } from "../requestMethods";
import { userRequest } from "../../src/requestMethods";


export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try {
        const res = await publicRequest.post("/auth/login", user);
        dispatch(loginSuccess(res.data));
        return res.data.name
    } catch (err) {
        dispatch(loginFailure());
    }
};

export const register = async (user) => {
    try {
        const result = await publicRequest.post("/auth/register", user);
        if (result.data) {
            alert("Your account has been created. Please login.");
            window.location.href = "/login";
        }
    } catch (err) {
    }
};

export const addCampaign = async (campaign) => {
    try {
        const result = await publicRequest.post("/campaign", campaign);
        if (result.data) {
            alert(
                "Thank you for your order! We have received it and will be in touch with you shortly. Your support is greatly appreciated.");
        }
        return result.data
    } catch (err) {
    }
};

export const generateSignature = async () => {
    try {
        const res = await userRequest.get(`/imagekit`);
        return res;
    } catch (err) {
        console.log("error");
    }
};

export const addNewsletter = async (email) => {
    try {
        const res = await publicRequest.post("/newsletter", email);
        if (res.data) {
            alert("You have subscribed to our newsletter successfully.");
        }
    } catch (err) {
        alert("Email already exists.");
    }
};

export const resetpassword = async (dispatch, user) => {
    try {
        const res = await publicRequest.post("/auth/resetpassword", user);
        if (res.data) {
            alert("You will receive a reset password email.");
            window.location.href = "/";
        }
    } catch (err) {
        alert("Email does not exist");
    }
};

export const updatepassword = async (token) => {
    try {
        const res = await publicRequest.post("/auth/updatepassword", token);
        if (res.data) {
            alert("Your account password has been updated. Please proceed to login.");
            window.location.href = "/login";
        }
    } catch (err) {
        alert("Failed to update password");
    }
};

export const updateUser = async (user, id) => {
    try {
        const res = await publicRequest.put(`/users/${id.userId}`, user);
        if (res.data) {
            alert("Your account has been updated.");
            window.location.href = "/";
        }
    } catch (err) {
        alert("Failed to update account");
    }
};

export const addWarrantyToProduct = async (payload) => {
    try {
        const res = await userRequest.post(`/warranty/addwarrantytoproduct`, payload);
        if (res.data) {
            alert("Warranty successfully added to product");
            window.location.href = "/warranty";
        }
    } catch (err) {
        alert("Warranty is not valid / is already in use")
    }
};



