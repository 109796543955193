import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import { mobile, tablet } from "../responsive";
import { publicRequest } from "../requestMethods";

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    padding: 0 4rem;
    background: black;
    flex-grow: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mobile({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
    ${tablet({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
`;

const Search = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await publicRequest.get(`/products/bestsellers`);
                setProducts(res.data);
            } catch (err) {}
        };
        getProducts();
    }, []);

    return (
        <MainContainer>

            <Container>
                {products
                          .slice(0, 4)
                          .map((item) => (
                              <Product item={item} key={item.title} />
                          ))}
            </Container>
        </MainContainer>
    );
};

export default Search;
