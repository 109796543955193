import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { userRequest } from "../requestMethods";
import { clearCart } from "../redux/cartRedux";

const Success = () => {
    const location = useLocation();
    const data = location.state
        ? location.state.address
        : undefined;
    const gateway = location.state.gateway
    const cart = location.state ? location.state.products : undefined;
    const serialCode = location.state ? location.state.serialCode : undefined;
    const currentUser = useSelector((state) => state.user.currentUser);
    const [orderId, setOrderId] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);
    const dispatch = useDispatch();

    let totalPrice = 0

    cart.products.forEach(item => {
        totalPrice += item.price * item.quantity;
    });

    let totalDiscount = 0

    useEffect(() => {
        if (data === undefined) {
            alert("Please make a valid purchase");
            window.location.href = "/";
        }
    });

    useEffect(() => {
        const createOrder = async () => {
            try {
                if (cart.length === 0) {
                    alert("No product added!!");
                    return;
                }

                const paypalAddress = `${data.address_line_1 ? `${data.address_line_1},` : ""}${
                    data.address_line_2 ? `${data.address_line_2},` : ""
                }${data.postal_code ? `${data.postal_code},` : ""}${
                    data.admin_area_2 ? `${data.admin_area_2},` : ""
                }${data.admin_area_1 ? `${data.admin_area_1},` : ""}${
                    data.country_code ? `${data.country_code},` : ""
                }`

                const stripeAddress = `${data.line1 ? `${data.line1},` : ""}${
                    data.line2 ? `${data.line2},` : ""
                }${data.postal_code ? `${data.postal_code},` : ""}${
                    data.state ? `${data.state},` : ""
                }${data.city ? `${data.city},` : ""}${
                    data.country ? `${data.country},` : ""
                }`

                const res = await userRequest.post("/orders", {
                    userId: currentUser._id,
                    products: cart.products.map((item) => ({
                        productId: item._id,
                        quantity: item.quantity,
                        code: `${item.model}-${item.color.color.name}`,
                        img: item.color.color.img,
                    })),
                    amount: totalPrice,
                    discount: totalDiscount + cart.discount,
                    affiliateCode: serialCode,
                    address: gateway === "paypal" ? paypalAddress : stripeAddress,
                    cashier: currentUser ? currentUser._id : undefined,
                    paymentType: gateway === "paypal" ? "paypal" : "ecommerce"
                });
                setOrderId(res.data._id);
                setInvoiceId(res.data.invoiceId);
                dispatch(clearCart());
                window.location.href = "/order";
            } catch {}
        };
        data && createOrder();
    }, [cart, data, currentUser, dispatch, serialCode, gateway, totalDiscount, totalPrice]);

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "black",
                color: "white",
            }}
        >
            {orderId
                ? `Order has been created successfully. Your order number is ${orderId} - Invoice number : ${invoiceId}`
                : `Successful. Your order is being prepared...`}
            <button
                style={{ padding: 10, marginTop: 20 }}
                onClick={() => (window.location.href = "/")}
            >
                Go to Homepage
            </button>
            <button
                style={{ padding: 10, marginTop: 20 }}
                onClick={() => (window.location.href = "/order")}
            >
                Check on Order
            </button>
        </div>
    );
};

export default Success;
