import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { register } from "../redux/apiCalls";
import { useState } from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import { toast } from "react-toastify";
import Footer from "../components/Footer";

const Image = styled.img`
    object-fit: cover;
    opacity: 0.4;
    width: 100vw;
    ${mobile({ height: "100%" })}
`;

const Header = styled.div`
    height: 120vh;
    width: 100vw;
    background: black;
    overflow: hidden;
    position: relative;
    ${mobile({ height: "150vh" })}
`;

const Section = styled.div`

    position: absolute;
    top: 40%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 100%;
    ${mobile({ top: "50%", left: "50%", display: "flex", justifyContent: "center" })}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    width: 40%;
    padding: 16px 32px;
    background-color: white;
    min-width: 300px;
    ${mobile({
        margin: "0rem",
        height: "100%",
        width: "95%",
    })}
    ${tablet({
        width: "85%",
        margin: "0rem",
        height: "100%",
    })}
`;

const Heading = styled.h1`
    font-size: 28px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    font-family: FuturaPT-Bold;
`;

const Line = styled.hr`
    width: 20%;
    margin-left: auto;
    margin-right: auto;
`;

const Title = styled.h1`
    font-family: FuturaPT-Demi;
    font-size: 18px;
    color: #1f2f4d;
    margin-top: 20px;
`;
const Form = styled.div`
    display: block;
    flex-wrap: wrap;
    ${mobile({ display: "flex", flexDirection: "column" })}
    ${tablet({ display: "flex", flexDirection: "column" })}
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const FullGridContainer = styled.div`
    display: grid;
    grid-template-columns: 100%;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const Input = styled.input`
    flex: 1;
    min-width: 100%;
    padding: 14px;
    ${mobile({ minWidth: "100%" })}
    ${tablet({ minWidth: "100%" })}
`;

const Button = styled.button`
margin-top: 30px;
margin-bottom: 30px;
    width: 95%;
    border: 1px black solid;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: FuturaPT-Bold;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "70vw" })}
`;

const ItemContainer = styled.div`
    margin: 0 2rem 0 0;

    ${mobile({ margin: "0 0 0 0" })}
`;

const Register = () => {
    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const result = await register({
                name,
                password,
                email,
                phoneNumber,
            });

            if (result) {
            } else {
                toast.error(`Phone Number or Email has been taken.`, {
                    position: "top-center",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    return (
        <>
            <Navbar />
            <Header>
                <Image
                    src={
                        "https://ik.imagekit.io/dnddecpho/Haofa/200523-%E5%93%81%E7%89%8C_%E8%B1%AA%E6%B3%95_%E5%A7%9A%E6%80%BB_1921-%E9%AB%98%E7%AB%AF%E7%B2%BE%E4%BF%AE_2%E4%B8%AA_%E6%89%8B%E6%A8%A103-02_DeUiEikEh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665388006291"
                    }
                    alt="About"
                ></Image>{" "}
                <Section>
                    <Wrapper>
                        <Heading>SIGN UP</Heading>
                        <Line />

                        <Form>
                            <FullGridContainer>
                                <ItemContainer>
                                    <Title>Full Name</Title>
                                    <Input
                                        id="grid-last-name"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </ItemContainer>
                                <ItemContainer>
                                    {" "}
                                    <Title>Email Address</Title>
                                    <Input
                                        id="grid-first-name"
                                        name="login"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </ItemContainer>
                               
                            </FullGridContainer>
                            <GridContainer>
                            <ItemContainer>
                                    <Title>Phone Number</Title>
                                    <Input
                                        id="grid-last-name"
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                    />
                                </ItemContainer>
                                <ItemContainer>
                                    <Title>Password</Title>
                                    <Input
                                        id="grid-last-name"
                                        name="password"
                                        type="password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </ItemContainer>

                            </GridContainer>
                            <Button onClick={handleClick}>CREATE</Button>
                        </Form>
                    </Wrapper>
                </Section>
            </Header>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </>
    );
};

export default Register;
