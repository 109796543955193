import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./AlternateProduct";
import { mobile, tablet } from "../responsive";
import { publicRequest } from "../requestMethods";
import { Send } from "@material-ui/icons";

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 3rem;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    padding: 0 4rem;
    flex-grow: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mobile({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
    ${tablet({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
`;

const SearchContainer = styled.div`
    padding: 5rem 8rem;
    display: flex;
    justify-content: space-between;
    ${mobile({
        display: "flex",
        padding: "2rem 0 1rem 0",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "12px",
    })}
    ${tablet({
        display: "flex",
        padding: "2rem 0 1rem 0",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "12px",
    })}
`;

const SearchResult = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;

const InputContainer = styled.div`
    width: 25%;
    height: 40px;
    background-color: white;
    display: flex;
    flex-direction: columns;
    border: 1px solid lightgray;
    ${mobile({ width: "80%", height: "30px", display: "flex" })}
    ${tablet({ width: "80%", height: "30px", display: "flex" })}
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.8rem" })}
`;

const Button = styled.button`
    flex: 1;
    border: none;
    background-color: teal;
    color: white;
`;

const Search = ({ cat, filters, model }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await publicRequest.get(`/products`);
                setProducts(res.data);
            } catch (err) {}
        };
        getProducts();
    }, []);

    useEffect(() => {
        cat &&
            setFilteredProducts(
                products.filter((item) =>
                    Object.entries(filters).every(([key, value]) =>
                        item[key].includes(value)
                    )
                )
            );
    }, [products, cat, filters]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    return (
        <MainContainer>
            
            <SearchContainer>
            <InputContainer>
                    <Input
                        onChange={handleSearch}
                        placeholder={"Search here"}
                    />
                    <Button>
                        <Send />
                    </Button>
                </InputContainer>
                <SearchResult>
                    Search Results :{" "}
                    {
                        products.filter((product) =>
                            product.model.includes(
                                search === "" && model === undefined
                                ? ""
                                : search === ""
                                ? model
                                : search
                            )
                        ).length
                    }{" "}
                    watches found
                </SearchResult>{" "}
               
            </SearchContainer>
            <Container>
                {cat
                    ? filteredProducts
                          .filter((product) =>
                              product.model.includes(
                                  search === "" && model === undefined
                                      ? ""
                                      : search === ""
                                      ? model
                                      : search
                              )
                          )
                          .map((item) => (
                              <Product item={item} key={`${item.title}`} />
                          ))
                    : products
                          .slice(0, 5)
                          .map((item) => (
                              <Product item={item} key={item.title} />
                          ))}
            </Container>
        </MainContainer>
    );
};

export default Search;
