import styled from "styled-components";
import ReactPlayer from "react-player";
import { mobile } from "../responsive";

const Container = styled.div`
    position: relative;
    height: 80vh;
    padding-top: 16%;
    ${mobile({
        height: "100%",
        position: "relative",
        paddingTop: "57%",
    })}
`;

const ProductVideo = (video) => {
    return (
        <Container>
            <ReactPlayer
                url={video.video}
                height="100%"
                width="100vw"
                controls={false}
                playing={true}
                muted={true}
                className="react-player"
                loop={true}
            />
        </Container>
    );
};

export default ProductVideo;
