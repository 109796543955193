import React, { useState } from "react"
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { updatepassword } from "../redux/apiCalls";
import { mobile } from "../responsive";
import Navbar from "../components/Navbar";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3)
        ),
        url("https://ik.imagekit.io/dnddecpho/Haofa/200523-%E5%93%81%E7%89%8C_%E8%B1%AA%E6%B3%95_%E5%A7%9A%E6%80%BB_1921-%E9%AB%98%E7%AB%AF%E7%B2%BE%E4%BF%AE_2%E4%B8%AA_%E5%9C%BA%E6%99%AF03-02_mvVaTOjVr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665388166000")
            center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 25%;
    padding: 20px;
    background-color: white;
    ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 300;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0;
    padding: 10px;
`;

const Button = styled.button`
    width: 40%;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    &:disabled {
        color: green;
        cursor: not-allowed;
    }
`;


const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("")
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            updatepassword({token, newPassword });
        } catch (err) {
            alert(err)
        }
    }

    return (
        <>
            <Navbar />
            <Container>
                <Wrapper>
                    <Title>Update New Password</Title>
                    <Form>
                        <Input
                            placeholder="New Password"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <Button onClick={handleSubmit}>Reset Password</Button>
                    </Form>
                </Wrapper>
            </Container>
        </>
    );
}
export default ResetPassword