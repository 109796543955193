import styled from "styled-components";
import ReactPlayer from "react-player";
import { mobile } from "../responsive";

const Container = styled.div`
    position: relative;
    padding-top: 57%;
    ${mobile({
        height: "100%"
    })}
`;

const Video = () => {
    return (
        <Container>
            <ReactPlayer
                url={
                "https://youtu.be/VEJqRKBiCB8"
                }
                height="100%"
                width="100%"
                controls={false}
                playing={true}
                muted={false}
                loop={true}
                className="react-player"
            />
        </Container>
    );
};

export default Video;
