import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { updateUser } from "../redux/apiCalls";
import { useState } from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import moment from "moment"
import Footer from "../components/Footer";

const Container = styled.div`
    width: 100vw;
    height: auto;
    background: linear-gradient(
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3)
        ),
        url("https://ik.imagekit.io/dnddecpho/Haofa/200523-%E5%93%81%E7%89%8C_%E8%B1%AA%E6%B3%95_%E5%A7%9A%E6%80%BB_1921-%E9%AB%98%E7%AB%AF%E7%B2%BE%E4%BF%AE_2%E4%B8%AA_%E6%89%8B%E6%A8%A103-02_DeUiEikEh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665388006291")
            bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
`;

const Wrapper = styled.div`
    width: 40%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: white;
    ${mobile({ width: "75%" })}
    ${tablet({ width: "80%" })}
`;

const Title = styled.h1`
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    padding-bottom: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

const InputItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    ${mobile({ flexDirection: "column" })}
`;

const InputTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 150px;
`;

const EmailInput = styled.input`
    flex: 1;
    padding: 10px;
`;


const ButtonContainer = styled.div`
    padding: 20px 0px;
    display: flex;
    justify-content: center;
`;

const Button = styled.div`
    width: 200px;
    text-align: center;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
`;

const Profile = () => {
    const user = useSelector((state) => state.user.currentUser);
    const userId = user._id;
    const handleClick = (e) => {
        e.preventDefault();
        try {
            updateUser({ inputs }, { userId });
        } catch (err) {
            console.log(err);
        }
    };

    const [inputs, setInputs] = useState({});
    const [editMode, setEditMode] = useState(false);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    return (
        <>
            <Navbar />
            <Container>
                <Wrapper>
                    <Title>My Profile</Title>
                    <Form>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Email</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="email"
                                placeholder={user.email ? user.email : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Name</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="name"
                                placeholder={user.name ? user.name : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Phone Number</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="phoneNumber"
                                placeholder={user.phoneNumber ? user.phoneNumber : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Password</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="password"
                                placeholder="******"
                                type="password"
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Date of Birth</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="dob"
                                value={user.dob ? moment(user.dob).format('YYYY-MM-DD') : undefined}
                                onChange={(e) => handleChange(e)}
                                type="date"
                            />
                        </InputItemContainer>
                        <InputItemContainer>
                            {" "}
                            <InputTitle>Address Line One</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="lineOne"
                                placeholder={user.address ? user.address.lineOne ? user.address.lineOne : "" : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>

                        <InputItemContainer>
                            {" "}
                            <InputTitle>Address Line Two</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="lineTwo"
                                placeholder={user.address ? user.address.lineTwo ? user.address.lineTwo : "" : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>

                        <InputItemContainer>
                            {" "}
                            <InputTitle>Address Zip</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="zip"
                            placeholder={user.address ? user.address.zip ? user.address.zip : "" : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>

                        <InputItemContainer>
                            {" "}
                            <InputTitle>Address City</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="city"
                                                       placeholder={user.address ? user.address.city ? user.address.city : "" : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>


                        <InputItemContainer>
                            {" "}
                            <InputTitle>Address Country</InputTitle>{" "}
                            <EmailInput
                                readOnly={editMode === false ? true : false}
                                name="country"
                                                                                  placeholder={user.address ? user.address.country ? user.address.country : "" : ""}
                                onChange={(e) => handleChange(e)}
                            />
                        </InputItemContainer>

                        <ButtonContainer style={ editMode === true ? {display: "none"} : {display: "flex"} }>
                            <Button onClick={() => setEditMode(true)}>
                                Edit
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer style={ editMode === true ? {display: "flex"} : {display: "none"} }>
                            <Button style={{background: "black"}} onClick={handleClick}>Update Account</Button>
                        </ButtonContainer>
                    </Form>
                </Wrapper>
            </Container>
            <Footer></Footer>
        </>
    );
};

export default Profile;
