import { useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { useHistory } from "react-router-dom";

const MainContainer = styled.div`
    background: #1f2f4d;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    flex-wrap: wrap;
    overflow: hidden;
    ${mobile({
        padding: "0px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    })}
    ${tablet({
        padding: "0px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    })}
`;

const MainCategoryContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    ${mobile({ padding: "0px", height: "20vh" })}
    ${tablet({ padding: "0px", height: "40vh" })}
`;

const CategoryContainer = styled.div`
    background-size: contain;
    background-position: center;
    &:hover {
        transform: scale(1.2);
        -webkit-transition: transform 0.4s ease-in-out;
    }
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;


const Series = styled.p`
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    text-align: center;
    max-width: 50vh;
    font-family: "FuturaPT-Bold";
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        padding: "0 1rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0 1rem",
    })}
`;

const Category = () => {
    // eslint-disable-next-line
    const [categories, setCategories] = useState([
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories_mrnEz217l_kbnR9Sy9Z_i4tp_op2U.webp?updatedAt=1705771797097",
            series: "Self-winding Tourbillon",
            cat: "self-winding",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories2_IZ7vOzTYD_tHyQmdcZww__tatM-IMfg.webp?updatedAt=1705771797116",
            series: "Manual Tourbillon",
            cat: "manual",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories3_REZZ2NaIsM_AYcEm2I_U_vg1trtKSb.webp?updatedAt=1705771797014",
            series: "Crystal Series",
            cat: "crystal",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories4_ssjpU4WV8_54q2qhv8V_d9-N3TzWU.webp?updatedAt=1705771796935",
            series: "Carousel Series",
            cat: "carousel",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories5_BYhmsuJ6X_of2thLj8L_l964m2s_jJ.webp?updatedAt=1705771797073",
            series: "Automatic Mechanical",
            cat: "mechanical",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/haofa/HAOFA_NewHomePage_Categories6_hJXuWEMYg_kXXvtiHmO_a3P-T41Zw.webp?updatedAt=1705771796946",
            series: "Ladies Series",
            cat: "women",
        },
    ]);

    const history = useHistory();
    
    return (
        <MainContainer>
            <Container>
                {categories.map((item) => (
                    <MainCategoryContainer
                    onClick={() => history.push(`products/${item.cat}`)}
                        style={{
                            background: `url(${item.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                        key={`${item.series}`}
                    >
                        <CategoryContainer>
                            <Series>{item.series}</Series>
                        </CategoryContainer>
                    </MainCategoryContainer>
                ))}
            </Container>
        </MainContainer>
    );
};

export default Category;
