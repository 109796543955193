import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import SearchProduct from "../components/Search";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Container = styled.div``;

const Hero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "none",
    })}
`;

const MobileHero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: none;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "grid",
    })}
`;

const Search = () => {
    const cat = "all";
    // eslint-disable-next-line
    const [filters, setFilters] = useState({});
    // eslint-disable-next-line
    const [sort, setSort] = useState("newest");
    const [model, setModel] = useState(undefined);

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        setModel(location.state ? location.state.model ? location.state.model : undefined : undefined);
    }, [location.state]);

    return (
        <Container>
            <Announcement />
            <Navbar />

            <Hero
                style={
                    cat === "self-winding"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New_laeKWNxBn.jpg?updatedAt=1686462379101`}`,
                          }
                        : cat === "manual"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New2_u1x6GlY2y.jpg?updatedAt=1686462378878`}`,
                          }
                        : cat === "crystal"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New3_QCwhhybus.jpg?updatedAt=1686462378813`}`,
                          }
                        : cat === "mechanical"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New5_NTJwM1pU1.jpg?updatedAt=1686462378826`}`,
                          }
                        : cat === "carousel"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New4_s9NNXQzDwp.jpg?updatedAt=1686462379002`}`,
                          }
                        : cat === "women"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New6_am9ZBGwk_.jpg?updatedAt=1686462378832`}`,
                          }
                        : {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New2_u1x6GlY2y.jpg?updatedAt=1686462378878`}`,
                          }
                }
            ></Hero>
            <MobileHero
                style={
                    cat === "self-winding"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New_yf9GJGsoB.jpg?updatedAt=1686462624428`}`,
                          }
                        : cat === "manual"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New2_WYZDp1qTU.jpg?updatedAt=1686462624435`}`,
                          }
                        : cat === "crystal"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New3_g8nXCi3mQc.jpg?updatedAt=1686462624402`}`,
                          }
                        : cat === "mechanical"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New5_jgcZFjgtm.jpg?updatedAt=1686462624401`}`,
                          }
                        : cat === "carousel"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New4_Bwyxu3qYY.jpg?updatedAt=1686462624409`}`,
                          }
                        : cat === "women"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New6_9kvk245Y5.jpg?updatedAt=1686462624539`}`,
                          }
                        : {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New2_WYZDp1qTU.jpg?updatedAt=1686462624435`}`,
                          }
                }
            ></MobileHero>
            <SearchProduct
                cat={cat}
                filters={filters}
                sort={sort}
                model={model !== undefined ? model : undefined}
            />
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default Search;
