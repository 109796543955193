import {
    Facebook,
    Instagram,
    MailOutline,
    Phone,
    Room,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom";
import tiktok from "../assets/tiktok.svg";
import xiaohongshu from "../assets/xiaohongshu.svg";

const Container = styled.div`
    padding: 20px 5rem;
    display: flex;
    ${mobile({ flexDirection: "column", padding: "0px" })}
    ${tablet({ flexDirection: "column", padding: "0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
    ${tablet({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
`;

const Logo = styled.img`
    max-height: 8vh;
    max-width: 22vh;
    ${mobile({ maxHeight: "15vh" })};
    ${tablet({ maxHeight: "15vh" })};
`;
const Desc = styled.p`
    margin: 20px 0px;
`;

const SocialContainer = styled.div`
    display: flex;
`;

const SocialIcon = styled.a`
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;

const Center = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    flex-wrap: wrap;
`;

const Right = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ backgroundColor: "#1f2f4d", color: "white", padding: "3rem" })}
    ${tablet({ backgroundColor: "#1f2f4d", color: "white", padding: "3rem" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Payment = styled.img`
    width: 50%;
`;

const CopyrightContainer = styled.div`
    background: #ac997b;
    color: white;
    padding: 20px 5rem;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", padding: "20px 3rem" })}
    ${tablet({ flexDirection: "column", padding: "20px 3rem" })}
`;

const Copyright = styled.div`
text-align: center;
    font-size: 0.9rem;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.8rem" })}
`;

const Footer = () => {
    return (
        <>
            <Container>
                <Left>
                    <Logo src="https://ik.imagekit.io/dnddecpho/Haofa/favicon_FRYwaCRKU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665372300464"></Logo>
                    <Desc>
                        Established in 1996, we are defined as an old watch
                        brand in China. Over the years, as the OEM of many
                        high-end Swiss brands, we have produced the best
                        Tourbillon watches in China.
                    </Desc>
                    <SocialContainer>
                        <SocialIcon
                            href="https://www.facebook.com/haofamalaysia/"
                            target="blank"
                            color="3B5999"
                        >
                            <Facebook />
                        </SocialIcon>
                        <SocialIcon
                            href="https://www.instagram.com/haofa_malaysia/"
                            target="blank"
                            color="E4405F"
                        >
                            <Instagram />
                        </SocialIcon>
                        <SocialIcon
                        href="https://www.tiktok.com/@haofamalaysiaofficial?_t=8eSrhYVkQTP&_r=1"
                        target="blank"
                        color="55ACEE"
                    >
                        <Payment src={tiktok}/>
                    </SocialIcon>
                    <SocialIcon
                        href="https://www.xiaohongshu.com/user/profile/64508c5c000000001400df2d?xhsshare=CopyLink&appuid=64508c5c000000001400df2d&apptime=1690858154"
                        target="blank"
                        color="f40a0a"
                    >
                        <Payment src={xiaohongshu}/>
                    </SocialIcon>
                    </SocialContainer>
                </Left>
                <Center>
                    <Title>Useful Links</Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/terms"
                        >
                            Terms
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/privacy"
                        >
                            Privacy Policy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/delivery"
                        >
                            Delivery Policy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/refund"
                        >
                            Refund Policy
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/location"
                        >
                            Store Locations
                        </Link>
                    </List>
                </Center>
                <Right>
                    <Title>Contact</Title>
                    <ContactItem>
                        <Room style={{ marginRight: "10px" }} /> Management Office (Msia HQ), B-1-01,
                        Residensi The HERZ, Jalan 3/33a, Kepong Baru, 52100
                        Kuala Lumpur, Malaysia
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{ marginRight: "10px" }} /> +6010 200 6200 - Customer Service
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{ marginRight: "10px" }} /> +6010 262 2600 - Sales & Enquiry
                    </ContactItem>
                    <ContactItem>
                        <MailOutline style={{ marginRight: "10px" }} />{" "}
                        support@haofa.my
                    </ContactItem>
                    <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
                </Right>
            </Container>
            <CopyrightContainer>
                <Copyright>
                    © 2024 HAOFA TOURBILLON (M) SDN. BHD.
                </Copyright>
            </CopyrightContainer>
        </>
    );
};

export default Footer;
