import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import CompleteComponent from "../components/Complete";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";

// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const Hero = styled.h1`
    padding: 20px;
    background-image: url("https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_AboutUs_ChIJ6182N.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805041802");
    background-position: center;
    background-size: cover;
    height: 40vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;

    ${mobile({
        height: "20vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_AboutUs_1__EH8QCe46nr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318773"})`,
    })}

    ${tablet({
        height: "40vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_AboutUs_1__EH8QCe46nr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318773"})`,
    })}
`;

const Complete = () => {
    return (
        <div>
            <Navbar />
            <Hero></Hero>
            <CompleteComponent />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Complete;
