import axios from "axios";

let BASE_URL = undefined;
if (process.env.NODE_ENV === "production") {
    BASE_URL = "https://initiumprojects.com/api/";
} else if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:5000/api/";
}

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: "&KZqXDh/Trf.ooWOdqX}Y)g@$'2UO0}09m9v1?BzcGum#RVVPH" },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: "&KZqXDh/Trf.ooWOdqX}Y)g@$'2UO0}09m9v1?BzcGum#RVVPH" },
});
