import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Category from "../components/Category";
import Video from "../components/Video";
import About from "../components/About";
import Outlet from "../components/Outlet";
import Social from "../components/Social";
import Slider from "../components/Slider";
import ImageSlider from "../components/ImageSlider";
import Showcase from "../components/Showcase";


// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const urls = [
    {url:"https://ik.imagekit.io/dnddecpho/cases/16682720648457__1_.png_-_1668273864_hXWv7B6V_", model: "1901-1", price: "7380"},
    {url:     "https://ik.imagekit.io/dnddecpho/cases/16682736970853__8_.png_-_1668275497_Hej8bpOC3", model: "1906", price: "3680"},
    {url:     "https://ik.imagekit.io/dnddecpho/haofa/17066967957333__3_.png_-_1706698594_RDzgb7ds7", model: "2323", price: "4380"},
        {url:     "https://ik.imagekit.io/dnddecpho/haofa/3_C-X-1VxiJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1671516454035", model: "1906L", price: "3880"},
   
]

const Home = () => {
    return (
        <div>
            <Navbar />
            <Video/>
            <Slider/>
            <Category />
            <About />
           <Showcase />
            <Outlet />
            <Social/>
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
