import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Label = styled.div`
    background: black;
    width: 20vh;
    font-size: 1.2rem;
    font-family: "FuturaPT-Bold";
    font-weight: 600;
    color: red;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1.5rem;
    ${mobile({
        fontSize: "1.2rem",
    })}
    ${tablet({
        padding: "0.25rem 0.25rem",
        fontSize: "1rem",
    })}
`;

const Title = styled.div`
    font-size: 1.4rem;
    font-family: "FuturaPT-Bold";
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-align: center;
    ${mobile({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1.5rem",
    })}
    ${tablet({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1.2rem",
    })}
`;

const Price = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    padding: 0.5rem 0;
    text-align: center;
    ${mobile({
        fontSize: "1rem",
    })}
    ${tablet({
        fontSize: "1.2rem",
    })}
`;

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 < images.length ? prevIndex + 1 : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 >= 0 ? prevIndex - 1 : images.length - 1
        );
    };

    return (
        <div
            className="relative"
        >
            <div className="lg:flex lg:justify-center bg-black text-white hidden overflow-hidden py-8">
                {Array.from({ length: 4 }).map((_, index) => {
                    const imageIndex = (currentIndex + index) % images.length;
                    const image = images[imageIndex];
                    const link = `/product/${image.model}`;
                    return (
                        <div
                            className="cursor-pointer"
                            onClick={() => (window.location.href = link)}
                            key={index}
                        >
                            <img
                                src={image.url}
                                alt={`Slide ${index + 1}`}
                                className="flex-shrink-0 h-40vh"
                            />
                            <div className="flex justify-center py-2">
                                <Label> BEST SELLER</Label>
                            </div>
                            <Title>HAOFA {image.model}</Title>
                            <Price>
                                RM {Number(image.price).toLocaleString("en-US")}
                            </Price>
                        </div>
                    );
                })}
            </div>

            <div className="flex lg:hidden overflow-hidden">
                {Array.from(
                    window.innerWidth > 481 ? { length: 3 } : { length: 1 }
                ).map((_, index) => {
                    const imageIndex = (currentIndex + index) % images.length;
                    const image = images[imageIndex];
                    const link = `/product/${image.model}`;

                    return (
                        <div
                            className="w-full p-8"
                            onClick={() => (window.location.href = link)}
                            key={index}
                        >
                            <img
                                src={image.url}
                                alt={`Slide ${index + 1}`}
                                className="w-full flex-shrink-0 h-35vh"
                            />
                            <div className="flex justify-center py-2">
                                <Label> BEST SELLER</Label>
                            </div>
                            <Title>HAOFA {image.model}</Title>
                            <Price>
                                RM {Number(image.price).toLocaleString("en-US")}
                            </Price>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            model: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default ImageSlider;
