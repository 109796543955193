import styled from "styled-components";
import { mobile } from "../responsive";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Announcement from "../components/Announcement";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: black;
    ${mobile({ margin:"0" })}
`;
const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Demi;
    font-size: 40px;
    margin: 50px 0;
    background: black;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const AboutContainer = styled.div`
    padding: 3rem 5rem;
    display: grid;
    background: white;
    ${mobile({ padding: "1rem 1.5rem", maxWidth: "100vh" })}
`;

const Heading = styled.h2`
    padding: 1.5rem 3rem;
    font-size: 22px;
    font-weight: 300;
    ${mobile({ textAlign: "left", padding: "0rem" })}
`;

const Desc = styled.div`
    padding: 0 3rem;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.5;
    ${mobile({ textAlign: "left", margin: "0rem 0rem", padding: "1rem 0rem" })}
`;

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Announcement />
            <Navbar />
            <Container>
                <Title>Privacy Policy</Title>
                <AboutContainer>
                    <Heading>COLLECTING PERSONAL INFORMATION</Heading>
                    <Desc>
                        When you visit the Site, we collect certain information
                        about your device, your interaction with the Site, and
                        information necessary to process your purchases. We may
                        also collect additional information if you contact us
                        for customer support. In this Privacy Policy, we refer
                        to any information that can uniquely identify an
                        individual (including the information below) as
                        “Personal Information”. See the list below for more
                        information about what Personal Information we collect
                        and why. Device information Examples of Personal
                        Information collected: version of web browser, IP
                        address, time zone, cookie information, what sites or
                        products you view, search terms, and how you interact
                        with the Site. Purpose of collection: to load the Site
                        accurately for you, and to perform analytics on Site
                        usage to optimize our Site. Source of collection:
                        Collected automatically when you access our Site using
                        cookies, log files, web beacons, tags, or pixels [ADD OR
                        SUBTRACT ANY OTHER TRACKING TECHNOLOGIES USED].
                        Disclosure for a business purpose: shared with our
                        processor Shopify [ADD ANY OTHER VENDORS WITH WHOM YOU
                        SHARE THIS INFORMATION]. Order information Examples of
                        Personal Information collected: name, billing address,
                        shipping address, payment information (including credit
                        card numbers [INSERT ANY OTHER PAYMENT TYPES ACCEPTED]),
                        email address, and phone number. Purpose of collection:
                        to provide products or services to you to fulfill our
                        contract, to process your payment information, arrange
                        for shipping, and provide you with invoices and/or order
                        confirmations, communicate with you, screen our orders
                        for potential risk or fraud, and when in line with the
                        preferences you have shared with us, provide you with
                        information or advertising relating to our products or
                        services. Source of collection: collected from you.
                        Disclosure for a business purpose: shared with our
                        processor Shopify [ADD ANY OTHER VENDORS WITH WHOM YOU
                        SHARE THIS INFORMATION. FOR EXAMPLE, SALES CHANNELS,
                        PAYMENT GATEWAYS, SHIPPING AND FULFILLMENT APPS].
                        Customer support information Examples of Personal
                        Information collected: [MODIFICATIONS TO THE INFORMATION
                        LISTED ABOVE OR ADDITIONAL INFORMATION AS NEEDED]
                        Purpose of collection: to provide customer support.
                        Source of collection: collected from you. Disclosure for
                        a business purpose: [ADD ANY VENDORS USED TO PROVIDE
                        CUSTOMER SUPPORT] [INSERT ANY OTHER INFORMATION YOU
                        COLLECT: OFFLINE DATA, PURCHASED MARKETING DATA/LISTS]
                        [INSERT FOLLOWING SECTION IF AGE RESTRICTION IS
                        REQUIRED]
                    </Desc>
                    <Heading>SHARING PERSONAL INFORMATION</Heading>
                    <Desc>
                        We share your Personal Information with service
                        providers to help us provide our services and fulfill
                        our contracts with you, as described above. For example:
                        We use Shopify to power our online store. You can read
                        more about how Shopify uses your Personal Information
                        here: https://www.shopify.com/legal/privacy. We may
                        share your Personal Information to comply with
                        applicable laws and regulations, to respond to a
                        subpoena, search warrant or other lawful request for
                        information we receive, or to otherwise protect our
                        rights. [INSERT INFORMATION ABOUT OTHER SERVICE
                        PROVIDERS] [INCLUDE FOLLOWING SECTION IF USING
                        REMARKETING OR TARGETED ADVERTISING]
                    </Desc>
                    <Heading>BEHAVIOURAL ADVERTISING</Heading>
                    <Desc>
                        As described above, we use your Personal Information to
                        provide you with targeted advertisements or marketing
                        communications we believe may be of interest to you. For
                        example: [INSERT IF APPLICABLE] We use Google Analytics
                        to help us understand how our customers use the Site.
                        You can read more about how Google uses your Personal
                        Information here:
                        https://policies.google.com/privacy?hl=en.You can also
                        opt-out of Google Analytics here:
                        https://tools.google.com/dlpage/gaoptout. [INSERT IF YOU
                        USE A THIRD PARTY MARKETING APP THAT COLLECTS
                        INFORMATION ABOUT BUYER ACTIVITY ON YOUR SITE] We share
                        information about your use of the Site, your purchases,
                        and your interaction with our ads on other websites with
                        our advertising partners. We collect and share some of
                        this information directly with our advertising partners,
                        and in some cases through the use of cookies or other
                        similar technologies (which you may consent to,
                        depending on your location). [INSERT OTHER ADVERTISING
                        SERVICES USED] For more information about how targeted
                        advertising works, you can visit the Network Advertising
                        Initiative’s (“NAI”) educational page at
                        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                        You can opt out of targeted advertising by: [INCLUDE
                        OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. COMMON
                        LINKS INCLUDE: FACEBOOK -
                        https://www.facebook.com/settings/?tab=ads GOOGLE -
                        https://www.google.com/settings/ads/anonymous BING -
                        https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads]
                        Additionally, you can opt out of some of these services
                        by visiting the Digital Advertising Alliance’s opt-out
                        portal at: http://optout.aboutads.info/.
                    </Desc>
                    <Heading>USING PERSONAL INFORMATION</Heading>
                    <Desc>
                        We use your personal Information to provide our services
                        to you, which includes: offering products for sale,
                        processing payments, shipping and fulfillment of your
                        order, and keeping you up to date on new products,
                        services, and offers. [INCLUDE THE FOLLOWING SECTION IF
                        YOUR STORE IS LOCATED IN OR IF YOU HAVE CUSTOMERS IN
                        EUROPE]
                    </Desc>
                </AboutContainer>
            </Container>
            <Newsletter />
            <Footer />
        </>
    );
};

export default Privacy;
